<template>
    <div class="profile__detail">

        <!-- FRAGEN -->
        <div class="list">
            <router-link v-for="ni in newsItems" v-bind:key="ni.id" :to="'/neuigkeiten/detail/'  + ni.id" class="list__item drilldown">
                <div class="list__item__content">
                    <div class="list__item__label">
                        {{ ni.title }}
                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'News',
    data() {
        return {
            newsItems: [],
        }
    },
    methods: {
    init(){
        let apiUrl = this.$store.getters.getApiUrl;
        axios
        .get(apiUrl + 'appNews/?fields=_,id,title,body,date&order=-date')
        .then(response => {
            this.newsItems = response.data.items;
        });
    },
},
mounted() {
    this.init();
},
}
</script>

<style lang="scss">
</style>